import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import NavButtons from 'components/Software/Windows/InstarVision/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "InstarVision v3 for Windows",
  "path": "/Software/Windows/InstarVision/System/",
  "dateChanged": "2018-01-25",
  "author": "Mike Polinowski",
  "excerpt": "UI language, Start-up view and TCP ports.",
  "image": "../P_SearchThumb_InstarVision_Windows.png",
  "social": "/images/Search/P_SearchThumb_InstarVision_Windows.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-Windows-InstarVision_white.webp",
  "chapter": "Software"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <SEOHelmet title='InstarVision v3 for Windows' dateChanged='2018-01-25' author='Mike Polinowski' tag='INSTAR IP Camera' description='The InstarVision Surveillance Center is an IP camera software which can support 64 cameras at the same time, including Network cameras, USB cameras remotely accessed secondary installations of the software.' image='/images/Search/P_SearchThumb_InstarVision_Windows.png' twitter='/images/Search/P_SearchThumb_InstarVision_Windows.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Software/Windows/InstarVision/System/' locationFR='/fr/Software/Windows/InstarVision/System/' crumbLabel="System" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/29114/Banner_en-InstarVision2-0_System.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "16.521739130434785%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAADCAYAAACTWi8uAAAACXBIWXMAAAsTAAALEwEAmpwYAAAAe0lEQVQI143HsQrCMBhF4f9V9Pkcuil279DEKeKiW/sqLq4ZGvABujfRkIZyBFscpRc+uEeYEsQA0UN6wXuYP5m1M8ZwOJaQI6JsQFuPdiPa5R/lRlSX/qq7xOk5UVaafbGjth6RW49ce6QZkNYjzaJdK7A939lcHt/+AN5QwO2mJK30AAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/683d2b80a4b23f74f2abd8dd74c1477d/e4706/Banner_en-InstarVision2-0_System.avif 230w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/d1af7/Banner_en-InstarVision2-0_System.avif 460w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/7f308/Banner_en-InstarVision2-0_System.avif 920w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/e1c99/Banner_en-InstarVision2-0_System.avif 1380w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/76ea5/Banner_en-InstarVision2-0_System.avif 1840w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/f696c/Banner_en-InstarVision2-0_System.avif 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/683d2b80a4b23f74f2abd8dd74c1477d/a0b58/Banner_en-InstarVision2-0_System.webp 230w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/bc10c/Banner_en-InstarVision2-0_System.webp 460w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/966d8/Banner_en-InstarVision2-0_System.webp 920w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/445df/Banner_en-InstarVision2-0_System.webp 1380w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/78de1/Banner_en-InstarVision2-0_System.webp 1840w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/882b9/Banner_en-InstarVision2-0_System.webp 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/683d2b80a4b23f74f2abd8dd74c1477d/81c8e/Banner_en-InstarVision2-0_System.png 230w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/08a84/Banner_en-InstarVision2-0_System.png 460w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/c0255/Banner_en-InstarVision2-0_System.png 920w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/b1001/Banner_en-InstarVision2-0_System.png 1380w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/161ec/Banner_en-InstarVision2-0_System.png 1840w", "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/29114/Banner_en-InstarVision2-0_System.png 1920w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/683d2b80a4b23f74f2abd8dd74c1477d/c0255/Banner_en-InstarVision2-0_System.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "side-navigation---system",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#side-navigation---system",
        "aria-label": "side navigation   system permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Side Navigation - System`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/3dde1/InstarVision_System_Menu.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "58.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAACXBIWXMAAAsTAAALEwEAmpwYAAADSElEQVQozx3MW09bBQDA8ZMoyhQXGGwUWi7raQv0Qns4vV/PoaUt2K4ttBVKL4CDQQbaxLD4sgQX3wzGD8BMlG1xMXEPLD6xJTOGCCZTEt8WE6OGaTCyGBDe/ib+PsBPcHlD9OgN6I0GRJMJq82GaDRhHDBjttmwyS4cbjeDDht2uwWnZEV2WPB5JMySjXgux8/PnvHp7ds83t5G0F7W06rrwOc04w+4mZoYYyIXwRsepKtPTzA0SL0S5GY1wkezUT6sRVidDjJTDNAZsKFLqny3s8OX9+/z9cOHCG6fG0UZopiKMF16k/pSioWZLOlUiKAyhD3qRckEWSwn+GK1xo1qGmk0gCbiocE+gDniY2Njg62tLTY3NxFCahB/wEF8NMBUKUG5NkYuk2RYdZFNyliDTtr8TtxXony8WueTlRWy+VFsSRXLsIxnNMzaB7dYX19nbW0NwR/yYfW4mK6kyU+OEBoJE4uHkD0DXJsMoPdKNDjt2BNO6rNFFmfKmJQQohKg02NGTg2z/egR+/v77O7uIohWL+daNGi6dDicFuweJ0aziKGvG7vNQLvBwKu9l2k09OKQrIgWC4K2g5e7dAgXWpAjfp78dMC9py/4/pd/EHQGPY3N52ltv0Rbu4aLmg4uabS0dPZwTqND39NBf68WXWsTzU2v0Ha+EU3L6xi7LiJqW4mFffz+2688/+NPDg8PEfRSBwGfkVpW4Woxzmwpxdq1HO8vTOCKKYgDWqLObvJxB3PjbkppD0nVQTUjUYj2MV/N/R8ePD/g+PgYQQo7SEVllt+Ks1pLszBXYOXtPLeWCihJFY/cRd7bSkY1MFMIsTCtsFhJUK8Gqc+NsHx1ihdHR5yennJycoIguiWMXplwLMhwXOFKMUt5Kk2tNI6cypJPy8xl+vEOaZgrXKCSd3JjPsLN5QTvvVvi+nyBo7//4uTkX87OzhDadc0ILS8hGDU0iN28ZrFgkkRScROdQwO0dTYh6t4gHe5hqSyRSzpYKvup5WVq1VHeuV5i59tvePrDj+zt7SEowyo2eRAp5MLud+NQIvSrCWJjKsVMCFXxkFKdJCMyI2qQyniManGM9JjCZDHFymKFO59/xoOvHnD37h3+A1Di06IBAxXZAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/e4706/InstarVision_System_Menu.avif 230w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/d1af7/InstarVision_System_Menu.avif 460w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/7f308/InstarVision_System_Menu.avif 920w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/7a54e/InstarVision_System_Menu.avif 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/a0b58/InstarVision_System_Menu.webp 230w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/bc10c/InstarVision_System_Menu.webp 460w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/966d8/InstarVision_System_Menu.webp 920w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/5304f/InstarVision_System_Menu.webp 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/81c8e/InstarVision_System_Menu.png 230w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/08a84/InstarVision_System_Menu.png 460w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/c0255/InstarVision_System_Menu.png 920w", "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/3dde1/InstarVision_System_Menu.png 1018w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/3d02f31706d991fd1b8e2cbdeccdd28b/c0255/InstarVision_System_Menu.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <h2 {...{
      "id": "how-to-use-the-rtsp-stream-in-vlc-player",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#how-to-use-the-rtsp-stream-in-vlc-player",
        "aria-label": "how to use the rtsp stream in vlc player permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`How to use the RTSP Stream in VLC Player`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/248ca62daadde3307bdd718b2bb138c3/2bef9/InstarVision2_RTSP-Stream_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACOElEQVQoz23O708SARzH8S93J2DCccASgbubLYEBTnMSybP+iWyrB/0HWTgf9MBmz1tPWusB9KBnFelEQMlJ44dgcqjzjgsMFHCNH5c/ModI40HTsmnrvdfTz/YBv3cmFo5+q1TLX8v5jVypUIpEYjMzgUg42tfXDyJU2i5rE0t+w8SSSzK5Tqd3uVxerxem30+urbFrXO5LNpdcZth1bjEWDwTm3r7xjI8/vj8y4hwddTpPPHjofDQ2NnRtAAAmJp643W54/uxpMDi/vJ7J8NlytVYRhGpNmA+FKJK0DQ7a7fbrZ9lsNofD0XO1B8XafD5/NpuFe3dv33QMZaZ926FoLZEUPjG7Syn/i5cAgCAIXEwkEnV0yMSSdoZhDg4OwNZnGrAYuEiUiyX5xWSJ39jZLHlevdYoJDfMJACgKIqchWGYQkGIJdJwOCwIAvSaDP291uJ2qbBVrFRrO3u79aP6lH/OoIZhu+p0jFwYE6oOOc4wqcPDH3C5U6PXU8WtQrVc2dvZ/b6/36g3PgSD/72NiEQymVwskbJcunl8DJ0arVarW04scVyaZVmeT+fz+cnJqSvd3Raz2XQuo9FotVopmkYxMctxP5tN6NLqurS6wOzsQmghFPq4GI/H4vFEIrGyurpysRSTWuFWb90ZBoA0zzcaRydjBMUomqZomqT+0JPUKVqnJ8/Tk6RMjiMoxvOfW60WmC1WpUqtIJS4gvhLjhO4QokTKjlO/EOpUndqut55PAzD/AJPIgSww0TAjQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/248ca62daadde3307bdd718b2bb138c3/e4706/InstarVision2_RTSP-Stream_01.avif 230w", "/en/static/248ca62daadde3307bdd718b2bb138c3/d1af7/InstarVision2_RTSP-Stream_01.avif 460w", "/en/static/248ca62daadde3307bdd718b2bb138c3/7f308/InstarVision2_RTSP-Stream_01.avif 920w", "/en/static/248ca62daadde3307bdd718b2bb138c3/e3723/InstarVision2_RTSP-Stream_01.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/248ca62daadde3307bdd718b2bb138c3/a0b58/InstarVision2_RTSP-Stream_01.webp 230w", "/en/static/248ca62daadde3307bdd718b2bb138c3/bc10c/InstarVision2_RTSP-Stream_01.webp 460w", "/en/static/248ca62daadde3307bdd718b2bb138c3/966d8/InstarVision2_RTSP-Stream_01.webp 920w", "/en/static/248ca62daadde3307bdd718b2bb138c3/a9a89/InstarVision2_RTSP-Stream_01.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/248ca62daadde3307bdd718b2bb138c3/81c8e/InstarVision2_RTSP-Stream_01.png 230w", "/en/static/248ca62daadde3307bdd718b2bb138c3/08a84/InstarVision2_RTSP-Stream_01.png 460w", "/en/static/248ca62daadde3307bdd718b2bb138c3/c0255/InstarVision2_RTSP-Stream_01.png 920w", "/en/static/248ca62daadde3307bdd718b2bb138c3/2bef9/InstarVision2_RTSP-Stream_01.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/248ca62daadde3307bdd718b2bb138c3/c0255/InstarVision2_RTSP-Stream_01.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`1`}</strong>{` - It is recommended to change the Webserver Port to a port higher than 8080. If you use your web server locally, you might want to deactivate the requirement for a login. It should be kept on if you plan to access the server from the internet. Make sure that InstarVision is running in the background, open the `}<a parentName="p" {...{
        "href": "/en/Software/Windows/VLC_Player/"
      }}>{`VLC Player`}</a>{` - go to the Media Menu and choose to `}<strong parentName="p">{`Open Network Stream`}</strong>{`. You can also just press `}<code>{`Ctrl + N`}</code>{` to open the network stream dialogue.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/2bef9/InstarVision2_RTSP-Stream_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACTElEQVQoz12OS08aYRSGz1xpuQ0zOB8gyk0xKSSUpuk/cMXORJs06cJFf0nd02r37VZRVEpdqaBtQaAyYUZqomJNuqq1I02IggwzXyO0adM3T05O3uTJOXB+UP+q1JunZ+pJQz35on3/gZs/DVU1mk1DvcStFr65we32Xzod3O3iqyvj4gLkdxuN8qdidmN3Ze396vqHtczO8urWYiq/nM6lVgrZrLS1XcvllHxezufru7sfM28zr9/I+Z365ia8epFcT6+K4hBFkizLmlgWIeT2eJALIYREhERBsLAsSRAUQbAMIwgCAKSWlo6OjmD26ZOHD+JmiwX6cTgcmXRa2a/u7xWrpZIiSWeHh4nJSQAgSZKiKBG52Dt3y5VK+/oaHt2PjAdG7Hb7QHaK4nFV0r6d66qKW61eq4UxfjwzAwAURdE0LSLEsKbtXO5SVSEWjQRGhzmOIwjiVhaExmnDwLjTvenpelfTMMbT09MAQNM0wzAul9tstZVK5Xa7DR6v12yxms3mwWWe5xsnDYyxruuGYWh9eWpq6s/bpNM5xLCmWk3W9R54hr0OXojH4+P9xGKxubnn8/PzL/tJJpMLCwuJRCIQDE5MTESj0XA4TFJ0rSb3ej1ALo93ZLRQKJbL5UqlIsvy3l6pUCgOKN7OgiRJiqLINfnzcX322SwAyIqiaRq4PcMkRfv8gd/4/MHQWDA0FhoLh8bDg93f730+v98f4DieICnl4ABjDPciUV5w2jiH1cb9i83usNn5/0qrneMFp+hyLy6lqtX9X8rjIHFgtoWNAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/e4706/InstarVision2_RTSP-Stream_02.avif 230w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/d1af7/InstarVision2_RTSP-Stream_02.avif 460w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/7f308/InstarVision2_RTSP-Stream_02.avif 920w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/e3723/InstarVision2_RTSP-Stream_02.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/a0b58/InstarVision2_RTSP-Stream_02.webp 230w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/bc10c/InstarVision2_RTSP-Stream_02.webp 460w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/966d8/InstarVision2_RTSP-Stream_02.webp 920w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/a9a89/InstarVision2_RTSP-Stream_02.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/81c8e/InstarVision2_RTSP-Stream_02.png 230w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/08a84/InstarVision2_RTSP-Stream_02.png 460w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/c0255/InstarVision2_RTSP-Stream_02.png 920w", "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/2bef9/InstarVision2_RTSP-Stream_02.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce87a2b9eaea93bce9d9f77a56cb042f/c0255/InstarVision2_RTSP-Stream_02.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`2`}</strong>{` - Type in the RTSP stream path (see above) and change the IP/DDNS address, RTSP port and channel (CH=1-64) according to your setup. You can use the local IP address of your InstarVision server from all computer connected to your local network. To access the RTSP stream over the internet, use a DDNS address from your local camera instead and forward the RTSP port to your InstarVision server.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/2bef9/InstarVision2_RTSP-Stream_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "56.086956521739125%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAALCAIAAADwazoUAAAACXBIWXMAAAsTAAALEwEAmpwYAAACqklEQVQozwGfAmD9ALKvscPAwunm6Ofk5trX2uDe4MC/wbCws7y7vSoqLAAAAAQFBwECBAECAwABAgwNDhITFBgZGpmZmba0tACtqqzS0NPc29za2dvQztHY19rMzM68vL64uLqKiop5enqBgYJ8fH19fn+Ig4Q2LzAAAAAEBQZ8fX2fn58AkIyLvby9u7m8vbq++vn7+Pf6/fv+/v7///7//v7+////8PDy2Njb5ufs/P3/ZWVnAAAACgsMr7Cw3dzdAFtXVElFRK+tr6upq+7t8P36/fz6/f///8fGzeTj6evr7sTDxru7vZ6eoKussE9RVAAAAAkKC8jIyPb29gAxKyU0MC2XlZewrrH08/X9+/79/P7///+qnYq7ta7g3+LDw8alo6Vzbm6Qj5FCREYAAAAICQq9vr7u7e0ALSkoJSEfeXZ0w8DA3t3f+fj57Oru3dnWwqx/hntveHN2dG9vW1RSV09LdXBwPkBBAAACDQ8QyMjI9/f3ABYXGRQUFXh1c357e0xISFxYWpOPkZWKgbKbbINyWUxCOkE2LFNMSFFJRV9ZVzIxMQABAgcICc/Q0P39/AAYGRoREhJFQT0hHRsgGxofGBg7NTRlXFKQfFd4Zk09MypEOi5LRUE6MipBNy0xLSUAAgQHCAnPz9D8/PwAGhwdCwwMIRsVGhQQIR0YLCUgHxsbDAsOGxcbIRsgHRgZHhoZIBkZFw8KJxwXIB0bAwUGCAkKzs/P+/v7ABUWFxEREpqVlJ6amKCfnamnpqupqaempqWkpamnqainqKmnpqilpKWioaWmoj9BQAAAAAUGB9LS0////wAmJygeHiCGhoeKjI2PkJGUlZWUlZaVlZaVl5eUlpeUlZWUlZaUlpaVlpeSl5RCRUMODxAWGBmpqarNzczp+0LmKDB/wAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/e4706/InstarVision2_RTSP-Stream_03.avif 230w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/d1af7/InstarVision2_RTSP-Stream_03.avif 460w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/7f308/InstarVision2_RTSP-Stream_03.avif 920w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/e3723/InstarVision2_RTSP-Stream_03.avif 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/a0b58/InstarVision2_RTSP-Stream_03.webp 230w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/bc10c/InstarVision2_RTSP-Stream_03.webp 460w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/966d8/InstarVision2_RTSP-Stream_03.webp 920w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/a9a89/InstarVision2_RTSP-Stream_03.webp 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/81c8e/InstarVision2_RTSP-Stream_03.png 230w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/08a84/InstarVision2_RTSP-Stream_03.png 460w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/c0255/InstarVision2_RTSP-Stream_03.png 920w", "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/2bef9/InstarVision2_RTSP-Stream_03.png 1024w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/fc8a4c30dd7d14a10bcdd703e9c12cbb/c0255/InstarVision2_RTSP-Stream_03.png",
              "alt": "InstarVision v3 for Windows",
              "title": "InstarVision v3 for Windows",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><strong parentName="p">{`3`}</strong>{` - The RTSP stream should start to run, once you pressed Play. You can also deactivate the requirement for a login - remember to remove the login from the RTSP stream path, if you do.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      